import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  //   DialogContentText,
  DialogTitle,
  Divider,
  Slide,
} from "@material-ui/core";
// import Slide from "@material-ui/Slide";
// import { TransitionProps } from "@material-ui/transitions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Modal = ({ children, open, handleClose }) => {
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        maxWidth={"xl"}
        fullWidth={true}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Administrador de Imagenes"}</DialogTitle>
        <Divider />
        <DialogContent>
          {/* <DialogContentText xt id="alert-dialog-slide-description">
            Let Google help apps determine location. This means sending
            anonymous location data to Google, even when no apps are running.
          </DialogContentText> */}
          {children}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Aceptar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Modal;
