import React from "react";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Public from "@material-ui/icons/Public";
import { Checkbox, IconButton } from "@material-ui/core";
import Flag from "react-world-flags";
require("dotenv").config();
const URL_BACKEND = process.env.REACT_APP_BACKENDHOST;

const useStyles = {
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  submit: {
    marginTop: 20,
  },
  flexContainer: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  flexContainerRow: {
    display: "flex",
  },
  flexElementRow: {
    display: "flex",
    alignItems: "flex-end",
  },
};

class CreateProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
      avales: null,
      firmas: null,
      file: null,
      producto_id: null,
      openDialog: false,
      errorMessage: "",
      openBar: false,
      collapsePaises: false,
      selectedPaises: [],
      paises: [
        ["Argentina", "ar"],
        ["Uruguay", "uy"],
        ["Chile", "cl"],
        ["México", "mx"],
        ["Perú", "pe"],
        ["Colombia", "co"],
        ["Costa Rica", "cr"],
        ["Bolivia", "bo"],
        ["Ecuador", "ec"],
        ["El Salvador", "sv"],
        ["Guatemala", "gt"],
        ["Honduras", "hn"],
        ["Nicaragua", "ni"],
        ["Panamá", "pa"],
        ["Paraguay", "py"],
      ],
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAvales = this.handleAvales.bind(this);
    this.handleFirmas = this.handleFirmas.bind(this);
    this.createFirmas = this.createFirmas.bind(this);
    this.createAvales = this.createAvales.bind(this);
    this.createProduct = this.createProduct.bind(this);
    this.handleDialog = this.handleDialog.bind(this);
    this.handleBar = this.handleBar.bind(this);
    this.buildArrayCheckbox = this.buildArrayCheckbox.bind(this);
    this.handlePaises = this.handlePaises.bind(this);
    this.handleSelectedPaises = this.handleSelectedPaises.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    if (
      (this.state.code === "" && !this.state.file) ||
      this.state.firmas === null
    ) {
      this.setState({
        errorMessage:
          "Debe completar el código del producto y las imágenes de las firmas.",
      });
      this.setState({ openDialog: true });
      return;
    }
    if (this.state.file) {
      this.createAllProduct();
    } else {
      this.createProduct();
    }
  }

  createAllProduct = () => {
    const url = URL_BACKEND + "/api/crear_producto_firma_aval";
    const formData = new FormData();
    formData.append("file_codes", this.state.file);
    this.state.selectedPaises.forEach((pais) => {
      formData.append("country", pais);
    });
    const AuthStr = "Bearer ".concat(localStorage.getItem("access_token"));
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: AuthStr,
      },
    };
    axios
      .post(url, formData, config)
      .then((response) => {
        console.log(response);
        response.data.map((code) => {
          this.setState({ producto_id: [code] });
          this.createFirmas();
          this.createAvales();
        });
        this.setState({ openBar: true });
      })
      .catch((error) => {
        console.log(error);
        let message = error.response.data.error; //"El producto con el código: " + this.state.code + " ya existe.";
        this.setState({ errorMessage: message });
        this.setState({ openDialog: true });
      });
  };

  createProduct = () => {
    const url = URL_BACKEND + "/api/crear_producto";
    const formData = new FormData();
    formData.append("code", this.state.code);
    this.state.selectedPaises.forEach((pais) => {
      formData.append("country", pais);
    });
    const AuthStr = "Bearer ".concat(localStorage.getItem("access_token"));
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: AuthStr,
      },
    };
    axios
      .post(url, formData, config)
      .then((response) => {
        this.setState({ producto_id: response.data });
        this.createFirmas();
        this.createAvales();
        this.setState({ openBar: true });
      })
      .catch((error) => {
        console.log(error);
        let message = error.response.data.error; //"El producto con el código: " + this.state.code + " ya existe.";
        this.setState({ errorMessage: message });
        this.setState({ openDialog: true });
      });
  };

  createFirmas() {
    const url = URL_BACKEND + "/api/crear_firma";
    for (var [key, value] of Object.entries(this.state.firmas)) {
      const formData = new FormData();

      formData.append("image", value);
      this.state.producto_id
        .map((p) => p.id)
        .map((id) => formData.append("product", id));

      const AuthStr = "Bearer ".concat(localStorage.getItem("access_token"));
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: AuthStr,
        },
      };
      axios
        .post(url, formData, config)
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log("Error");
        });
    }
  }

  createAvales() {
    const url = URL_BACKEND + "/api/crear_aval";
    console.log("Creando avales");
    if (this.state.avales) {
      for (var [key, value] of Object.entries(this.state.avales)) {
        const formData = new FormData();

        formData.append("image", value);
        this.state.producto_id
          .map((p) => p.id)
          .map((id) => formData.append("product", id));

        const AuthStr = "Bearer ".concat(localStorage.getItem("access_token"));
        const config = {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: AuthStr,
          },
        };
        axios
          .post(url, formData, config)
          .then(function (response) {
            console.log(response);
          })
          .catch(function (error) {
            console.log("Error");
          });
      }
    }
  }
  handleSelectedPaises = (value) => {
    if (this.state.selectedPaises.includes(value)) {
      this.setState({
        selectedPaises: this.state.selectedPaises.filter(
          (pais) => pais !== value
        ),
      });
    } else {
      this.setState({
        selectedPaises: [...this.state.selectedPaises, value],
      });
    }
  };
  handlePaises = (value) => {
    this.setState({ collapsePaises: !value });
  };

  handleFirmas = (files) => {
    this.setState({ firmas: files });
  };

  handleAvales = (files) => {
    this.setState({ avales: files });
  };

  handleDialog = () => {
    this.setState({ openDialog: false });
  };

  handleBar = () => {
    this.setState({ openBar: false });
  };

  buildArrayCheckbox = () => {
    const columnValue = this.state.paises.length / 3;
    const response = [];
    for (var i = 0; i < 3; i++) {
      response.push(
        this.state.paises.slice(i * columnValue, (i + 1) * columnValue)
      );
    }
    return response;
  };

  render() {
    if (this.state.firmas) {
      var listFirmas = [];
      for (var [key, value] of Object.entries(this.state.firmas)) {
        let item = <li key={key}>{value.name}</li>;
        listFirmas[key] = item;
      }
    }

    if (this.state.avales) {
      var listAvales = [];
      for (var [key2, value2] of Object.entries(this.state.avales)) {
        let item = <li key={key2}>{value2.name}</li>;
        listAvales[key2] = item;
      }
    }

    return (
      <div style={useStyles.root}>
        <form noValidate onSubmit={(e) => this.handleSubmit(e)}>
          <Typography component="h1" variant="h5">
            Crear producto
          </Typography>
          <div style={useStyles.flexContainerRow}>
            <TextField
              name="codigo"
              label="Codigo"
              type="text"
              margin="normal"
              fullWidth
              placeholder="Codigo"
              onChange={(e) => this.setState({ code: e.target.value })}
            />
            <div style={useStyles.flexElementRow}>
              <label htmlFor="contained-button-filecode">
                <IconButton component="span">
                  <CloudUploadIcon />
                </IconButton>
              </label>
            </div>
            <input
              style={{ display: "none" }}
              id="contained-button-filecode"
              type="file"
              accept=".xls,.xlsx"
              onChange={(e) => {
                this.setState({ file: e.target.files[0] });
              }}
            />
          </div>
          {this.state.file && (
            <ul>Se selecciono el archivo de codigos: {this.state.file.name}</ul>
          )}
          <label htmlFor="contained-button-countrys">
            <Button
              variant="contained"
              color="primary"
              component="span"
              style={{ marginTop: 20 }}
              fullWidth
              onClick={() => this.handlePaises(this.collapsePaises)}
            >
              <Public style={{ marginRight: 10 }} />
              Seleccionar País/es
            </Button>
          </label>
          {this.state.collapsePaises && (
            <div className={useStyles.flexContainer}>
              <div className={useStyles.flexContainer}>
                {this.buildArrayCheckbox().map((row, index) => (
                  <div key={index}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {row.map(([pais, code], index) => (
                        <div
                          key={index}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Checkbox
                            color="primary"
                            value={pais}
                            name={pais}
                            onChange={(e) =>
                              this.handleSelectedPaises(e.target.name)
                            }
                          />
                          <Flag
                            code={code}
                            height={12}
                            style={{ marginRight: 10 }}
                          />
                          <Typography>{pais}</Typography>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="contained-button-file-firmas"
            multiple
            type="file"
            name="firmas"
            onChange={(e) => this.handleFirmas(e.target.files)}
          />
          <label htmlFor="contained-button-file-firmas">
            <Button
              variant="contained"
              color="primary"
              component="span"
              style={{ marginTop: 30 }}
              fullWidth
            >
              <CloudUploadIcon style={{ marginRight: 10 }} />
              Seleccionar firmas
            </Button>
          </label>

          {/* Listado de archivos de firmas */}
          <ul>{listFirmas}</ul>
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="contained-button-file"
            multiple
            type="file"
            name="avales"
            // onChange={(e) => this.setState({avales:e.target.files})}
            onChange={(e) => this.handleAvales(e.target.files)}
          />
          <label htmlFor="contained-button-file">
            <Button
              variant="contained"
              color="primary"
              component="span"
              style={{ marginTop: 20 }}
              fullWidth
            >
              <CloudUploadIcon style={{ marginRight: 10 }} />
              Seleccionar avales
            </Button>
          </label>
          {/* Listado de archivos de avales */}
          <ul>{listAvales}</ul>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={useStyles.submit}
          >
            Guardar
          </Button>
        </form>

        <div>
          <Dialog
            open={this.state.openDialog}
            onClose={() => this.handleDialog()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Error</DialogTitle>
            <DialogContent id="alert-dialog-description">
              <DialogContentText id="alert-dialog-description">
                {this.state.errorMessage}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.handleDialog()} color="primary">
                Aceptar
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <div>
          <Snackbar
            open={this.state.openBar}
            autoHideDuration={6000}
            onClose={() => this.handleBar()}
          >
            <Alert
              onClose={() => this.handleBar()}
              severity="success"
              variant="filled"
            >
              Producto creado con exito!
            </Alert>
          </Snackbar>
        </div>
      </div>
    );
  }
}

export default CreateProduct;
