import React from 'react';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import Done from '@material-ui/icons/Done';
import Cancel from '@material-ui/icons/Cancel';
import Send from '@material-ui/icons/Send';


const useStyles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  submit: {
    marginTop: 20,
    marginBottom: 13
  }
};
require('dotenv').config();
const URL_BACKEND = process.env.REACT_APP_BACKENDHOST


class GenerateDiplomas extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      file: null,
      observations: '',
      openDialog: false,
      loading: false,
      success: false,
      toSend:false,
      toList:false,
      mails:[],
      mailSended:[],
      sendingMails:false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDialog = this.handleDialog.bind(this);
    this.handlePrepareMails = this.handlePrepareMails.bind(this)
    this.handleSendMails = this.handleSendMails.bind(this)
    this.mailStatus = this.mailStatus.bind(this)
  }

  mailStatus(index,status=false){
    const aux = this.state.mailSended
    aux[index]=status
    this.setState({mailSended:aux})
  }

  handlePrepareMails() { 
    const url = URL_BACKEND+'/api/get_emails_file';
    this.setState({loading: true});
    const AuthStr = 'Bearer '.concat(localStorage.getItem('access_token'));
    const config = {
      headers: {
          'content-type': 'application/json',
          Authorization: AuthStr
      }
    };
    axios.get(url,config )
    .then((response) => {
        this.setState({mails:response.data})
        this.setState({toSend:true})
    })
    .catch(function (error) {
      console.log("Error");
    })
    .finally(() => this.setState({loading: false}));
  }

  handleSendMails() {
    const url = URL_BACKEND+'/api/email';
    const AuthStr = 'Bearer '.concat(localStorage.getItem('access_token'));
    const config = {
      headers: {
          'content-type': 'application/json',
          Authorization: AuthStr
      }
    };
    for (let index = 0; index < this.state.mails.length; index++) {
      this.setState({sendingMails:true})
      const mail = this.state.mails[index];
      const params = {send_to:mail.split('.pdf')[0]}
      const url_api =  url+`?${new URLSearchParams(params).toString()}`;
      axios.get(url_api,config )
      .then((response) => {
          console.log(response);
          this.mailStatus(index,true)
      })
      .catch((error) => {
        this.mailStatus(index)
        console.log(`Error Al enviar el mail a ${mail}`);
      })
      .finally(() => this.setState({sendingMails: false}));
    }
    this.setState({toSend:false})
  }

  handleSubmit(e) {
    e.preventDefault();

    if (!this.state.file) {
      this.setState({openDialog: true});
      return
    }

    this.setState({loading: true});

    const url = URL_BACKEND+'/api/generar_diplomas';

    const formData = new FormData();
    formData.append('file', this.state.file);
    formData.append('observations', this.state.observations)
    formData.append('file_name', this.state.file.name)
    formData.append('user_id', localStorage.getItem('user_id'))
    const AuthStr = 'Bearer '.concat(localStorage.getItem('access_token'));
    const config = {
      headers: {
          'content-type': 'multipart/form-data',
          Authorization: AuthStr
      }
    };
    axios.post(url, formData, config)
    .then((response) => {
      this.setState({file: null});
      this.setState({success: true});
      window.location.replace(URL_BACKEND + "/media/diplomas/"+response.data.url_file);
      this.handlePrepareMails()
    })
    .catch(function (error) {
      console.log("Error");
    })
    .finally(() => this.setState({loading: false}));
  
  }

  handleDialog = () => {
    this.setState({openDialog: false});
  }

  render() {
    var name_file = '';
    this.state.file ? name_file = this.state.file.name : name_file = '';

    return (
        <div style={useStyles.root}>
          <Typography component="h1" variant="h5">
            Generar Diplomas
          </Typography>
          <div>
            <form noValidate onSubmit={e => this.handleSubmit(e)}>

              <input
                style={{display: 'none'}}
                id="contained-button-file"
                type="file"
                accept=".xls,.xlsx"
                onChange={(e) => this.setState({file:e.target.files[0]})}
              />
              <label htmlFor="contained-button-file">
                <Button variant="contained" color="primary" component="span" style={{marginTop: 20}}>
                  <CloudUploadIcon style={{marginRight: 10}} />
                  Seleccionar archivo
                </Button>
              </label>

              <TextField
                id="standard-full-width"
                style={{ margin: 8 }}
                name='observations'
                label='Observaciones'
                type='text'
                fullWidth
                placeholder='Observaciones'
                onChange={(e) => this.setState({observations:e.target.value})}
                />
              
              <Button 
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                style={useStyles.submit}
                >
                Generar
              </Button>

              {this.state.loading && <CircularProgress /> }
            
            </form>
                {this.state.toSend && 
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={useStyles.submit}
                  onClick={this.handleSendMails }
                >
                  Enviar Mails
                </Button>}
              {this.state.sendingMails && <LinearProgress />}
              {this.state.mails && this.state.mails.length > 0 && 
              <div>
                  {this.state.mails.map(
                    (mail,i) => {
                    const [mail_user,product] = mail.split('#')[0].split("&")
                    let icon;
                    if(this.state.mailSended && this.state.mailSended){
                      if(this.state.mailSended[i]===undefined)
                        icon = <Send/> 
                      else
                        icon = this.state.mailSended[i]? <Done/>:<Cancel/>
                    }

                    return <div key={i} style={{display:'flex'}}>
                      {icon}
                      <Typography style={{marginLeft:10}}>{`Envio de Diploma al mail: ${mail_user} con el código de producto: ${product}`}</Typography>
                    </div>
                    })
                  }
              </div>}
            {/* Muestro el nombre del archivo que seleccionaron */}      
            {name_file &&
              <div>
                <p style={{fontWeight: 'bold'}}>Archivo seleccionado:</p>
                <p style={{fontWeight: 'bold'}}>{name_file}</p>
              </div>
            }
          </div>

          <div>
            <Dialog
              open={this.state.openDialog}
              onClose={() => this.handleDialog()}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Error
              </DialogTitle>
              <DialogContent id="alert-dialog-description">
                <DialogContentText id="alert-dialog-description">
                  Debe seleccionar un archivo excel con la información.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.handleDialog()} color="primary">
                  Aceptar
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
    );
  }
}

export default GenerateDiplomas
