import React, { useEffect, useState } from "react";
import axios from "axios";
import MaterialTable from "material-table";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import { Typography } from "@material-ui/core";

require("dotenv").config();
const URL_BACKEND = process.env.REACT_APP_BACKENDHOST;

const useStyles = {
  card: {
    maxWidth: 345,
  },
  media: {
    width: 345,
    height: "auto",
  },
  root: {
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'center',
    // openDialog: false,
  },
};

const TemplateList = () => {
  const [openModal, setOpenModal] = useState(false);
  const [templateToDelete, setTemplateToDelete] = useState();
  const [listTemplates, setListTemplates] = useState([]);
  const [templateIdDelete, setTemplateIdDelete] = useState();

  useEffect(() => {
    const url = URL_BACKEND + "/api/templates";
    const AuthStr = "Bearer ".concat(localStorage.getItem("access_token"));

    axios
      .get(url, { headers: { Authorization: AuthStr } })
      .then((response) => {
        setListTemplates(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const url = URL_BACKEND + "/api/eliminar_template";
    const AuthStr = "Bearer ".concat(localStorage.getItem("access_token"));
    if (templateIdDelete) {
      axios.delete(`${url}/${templateIdDelete}`, {
        headers: { Authorization: AuthStr },
      });
      setTemplateIdDelete();
    }
  }, [templateIdDelete]);
  return (
    <div>
      <div>
        <MaterialTable
          title="Templates"
          columns={[
            { title: "Id", field: "id" },
            {
              title: "Nombre",
              field: "name",
              render: (rowData) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography>{rowData.name}</Typography>
                </div>
              ),
            },
            {
              title: "Imagen Template",
              field: "image",
              render: (rowData) => {
                return (
                  <Card style={useStyles.card}>
                    <CardActionArea>
                      <CardMedia>
                        <a href={(rowData.image).replace("http://oceano-rest-api-server:8000", "http://64.227.27.211")} target="_blank">
                          <img
                            style={useStyles.media}
                            alt="complex"
                            src={(rowData.image).replace("http://oceano-rest-api-server:8000", "http://64.227.27.211")}
                          />
                        </a>
                      </CardMedia>
                    </CardActionArea>
                  </Card>
                );
              },
            },

            {
              title: "Eliminar",
              field: "delete",
              render: (rowData) => (
                <IconButton
                  onClick={() => {
                    setOpenModal(true);
                    setTemplateToDelete(rowData.id);
                  }}
                  aria-label="delete"
                >
                  <DeleteIcon />
                </IconButton>
              ),
            },
          ]}
          data={listTemplates}
        />
      </div>
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Eliminar</DialogTitle>
        <DialogContent id="alert-dialog-description">
          <DialogContentText style={{ display: "inline" }}>
            {"Desea eliminar el template "}
          </DialogContentText>
          <DialogContentText style={{ display: "inline", fontWeight: "bold" }}>
            {templateToDelete}
          </DialogContentText>
          <DialogContentText style={{ display: "inline" }}>
            {"?"}
          </DialogContentText>
          <DialogActions>
            <Button onClick={() => setOpenModal(false)} color="primary">
              Cancelar
            </Button>
            <Button
              onClick={() => {
                setOpenModal(false);
                setTemplateIdDelete(templateToDelete);
                setListTemplates(
                  listTemplates.filter((t) => t.id !== templateToDelete)
                );
              }}
              color="primary"
              autoFocus
            >
              Aceptar
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TemplateList;
