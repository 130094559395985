import React, { useState } from "react";
import { Button, Typography } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CardImage from "./Card";

const useStyles = {
  flexContainerColumn: {
    display: "flex",
    flexDirection: "column",
  },
  flexContainerRow: {
    display: "flex",
    flexWrap: "wrap",
  },
  boxImage: {
    maxWidth: 250,
    marginLeft: 25,
    marginBottom: 15,
  },
  text: {
    display: "flex",
    justifyContent: "center",
  },
  emptyList: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: 25,
    background: "#E1E1E1",
  },
  "& #content-files": {
    paddingTop: 200,
  },
};

const ModalContentFiles = ({ data, setData, title = "Imágenes" }) => {
  const handleDelete = (key) => {
    setData(data.filter((d, i) => i !== key));
  };

  const handleAddImage = (files) => {
    setData([...data, ...files]);
  };
  return (
    <div>
      <div id="root" style={useStyles.flexContainerColumn}>
        <div
          id="header"
          style={{ ...useStyles.flexContainerRow, justifyContent: "flex-end" }}
        >
          <input
            type="file"
            accept=".png,.jpeg,.jpg"
            style={{ display: "none" }}
            id="contained-image-file"
            multiple
            name="templates"
            onChange={(e) => handleAddImage(e.target.files)}
          />
          <label htmlFor="contained-image-file">
            <Button
              variant="contained"
              color="primary"
              component="span"
              style={{ marginTop: 30 }}
            >
              <CloudUploadIcon style={{ marginRight: 10 }} />
              Seleccionar Imagen
            </Button>
          </label>
        </div>
        <div style={useStyles.text}>
          <Typography variant="h4">{title}</Typography>
        </div>
        <div id="content-files" style={useStyles.flexContainerRow}>
          {data && data.length > 0 ? (
            data.map((file, i) => (
              <div key={i} style={useStyles.boxImage}>
                <CardImage
                  headerTitle={file.name}
                  image={{ image: URL.createObjectURL(file) }}
                  key={i}
                  handleDelete={() => handleDelete(i)}
                />
              </div>
            ))
          ) : (
            <div style={useStyles.emptyList}>
              <Typography variant="h6">
                Aún no se han cargado ninguna imagen
              </Typography>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalContentFiles;
