import React, { useEffect, useState } from 'react';
import axios from 'axios';
import MaterialTable from 'material-table';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Tooltip } from '@material-ui/core';
import CloudDownload from "@material-ui/icons/CloudDownload";

require('dotenv').config();
const URL_BACKEND = process.env.REACT_APP_BACKENDHOST

const HistoryList = () => {
  const [history, setHistory] = useState([])
  const [open, setOpen] = useState(false)
  const AuthStr = 'Bearer '.concat(localStorage.getItem('access_token'));
  useEffect(() => {
    const url = URL_BACKEND+'/api/historial';
    axios.get(url, { headers: { Authorization: AuthStr } })
    .then((response) => {
      setHistory(response.data);
    })
    .catch(function (error) {
      console.log(error);
    })
  }, [])


  const DownloadZipFile = (data) => {
    if (data?.file_path) {
      const file_name =data.file_path.split('/').pop() 
      window.location.replace(URL_BACKEND + "/media/diplomas/"+file_name);
    }
    else{
      setOpen(true)
    }
  }

  return (
    <div>

        <div>
          <MaterialTable
            title="Historial"
            columns={[
              { title: 'ID', field: 'id' },
              // { title: 'Nombre', field: 'user.first_name' },
              { title: 'Email', field: 'user.email' },
              { title: 'Fecha', field: 'date' },
              { title: 'Archivo', field: 'file_name' },
              { title: 'Observaciones', field: 'observations' },
              { title: 'Archivo Generado',
                field: 'file',
                render: (rowData) => (
                  <Tooltip title="Descargar" placement='right-end'>
                    <IconButton 
                      onClick={()=>DownloadZipFile(rowData)}>
                        <CloudDownload />
                    </IconButton>
                  </Tooltip>
                )}
            ]}
            data={history}
            options={{
              pageSize: 10,
              pageSizeOptions: [20,25],
          }}
          />
        </div>
        <div>
          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Error
            </DialogTitle>
            <DialogContent id="alert-dialog-description">
              <DialogContentText id="alert-dialog-description">
                No se encuentra un archivo asociado a la fila seleccionada
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)} color="primary">
                Aceptar
              </Button>
            </DialogActions>
          </Dialog>
      </div>
    </div>
  );
}


export default HistoryList;