import React, { useState } from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  IconButton,
  //   CardActions,
  //   Typography,
  CardHeader,
} from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";
import Alert from "./Alert";

export default function CardImage({
  content,
  image = {},
  headerTitle = "Titulo de la card",
  handleDelete,
}) {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <Card sx={{ maxWidth: 140 }} onClick={() => setOpen(true)}>
        <CardActionArea>
          <CardHeader
            subheader={headerTitle}
            // action={
            //   <IconButton aria-label="delete image" onClick={handleDelete}>
            //     <Delete />
            //   </IconButton>
            // }
          />
          <CardMedia
            component="img"
            height="140"
            image="https://image.shutterstock.com/image-vector/no-image-vector-symbol-missing-600w-1310632172.jpg"
            alt="image loading"
            {...image}
          />
          <CardContent>{content}</CardContent>
        </CardActionArea>
      </Card>
      <Alert
        open={open}
        handleAccept={() => {
          handleDelete();
          setOpen(false);
        }}
        handleClose={() => setOpen(false)}
        title={"¿Está seguro?"}
        description={"Confirma que quiere eliminar un elemento?"}
      />
    </div>
  );
}
