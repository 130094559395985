import { useState } from "react";
import React from "react";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { Checkbox } from "@material-ui/core";

require("dotenv").config();
const URL_BACKEND = process.env.REACT_APP_BACKENDHOST;

const useStyles = {
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  submit: {
    marginTop: 20,
  },
};

const CreateTemplates = () => {
  const [openBar, setOpenBar] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [baseTemplate, setBaseTemplate] = useState();
  const [templateName, setTemplateName] = useState();

  const validateForm = (e) => {
    e.preventDefault();
    if (baseTemplate && templateName) return true;
    else {
      setErrorMessage(
        "Debe ingresar el nombre del Template y la imagen correspondiente del mismo."
      );
      setOpenDialog(true);
      return false;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm(e)) {
      const url = URL_BACKEND + "/api/crear_template";
      if (baseTemplate) {
        for (var [key, value] of Object.entries(baseTemplate)) {
          const formData = new FormData();

          formData.append("image", value);
          formData.append("name", templateName);

          const AuthStr = "Bearer ".concat(
            localStorage.getItem("access_token")
          );
          const config = {
            headers: {
              "content-type": "multipart/form-data",
              Authorization: AuthStr,
            },
          };
          axios
            .post(url, formData, config)
            .then(function (response) {
              setOpenBar(true);
            })
            .catch(function (error) {
              setErrorMessage(error.response.data.detail);
              setOpenDialog(true);
            });
        }
      }
    }
  };

  return (
    <div style={useStyles.root}>
      <form noValidate onSubmit={(event) => handleSubmit(event)}>
        <Typography component="h1" variant="h5">
          Crear Template
        </Typography>
        <TextField
          name="name"
          label="Nombre"
          type="text"
          margin="normal"
          fullWidth
          placeholder="Nombre"
          onChange={(e) => setTemplateName(e.target.value)}
        />
        <input
          accept="image/*"
          style={{ display: "none" }}
          id="contained-button-file"
          multiple
          type="file"
          name="templates"
          onChange={(e) => setBaseTemplate(e.target.files)}
        />
        <label htmlFor="contained-button-file">
          <Button
            variant="contained"
            color="primary"
            component="span"
            style={{ marginTop: 30 }}
            fullWidth
          >
            <CloudUploadIcon style={{ marginRight: 10 }} />
            Seleccionar Templates
          </Button>
        </label>
        <ul>{baseTemplate && baseTemplate.length && baseTemplate[0]?.name}</ul>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          style={useStyles.submit}
        >
          Guardar
        </Button>
      </form>
      <div>
        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Error</DialogTitle>
          <DialogContent id="alert-dialog-description">
            <DialogContentText id="alert-dialog-description">
              {errorMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)} color="primary">
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <div>
        <Snackbar
          open={openBar}
          autoHideDuration={6000}
          onClose={() => setOpenBar(false)}
        >
          <Alert
            onClose={() => setOpenBar(false)}
            severity="success"
            variant="filled"
          >
            Template creado con exito!
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};

export default CreateTemplates;
