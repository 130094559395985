import { useState } from "react";
import React from "react";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import TableChart from "@material-ui/icons/TableChart";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Modal from "./Modal";
import ModalContentFiles from "./ModalContentFiles";

require("dotenv").config();
const URL_BACKEND = process.env.REACT_APP_BACKENDHOST;

const useStyles = {
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  submit: {
    marginTop: 20,
  },
};

const ImportData = () => {
  const [openBar, setOpenBar] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [excelFile, setExcelFile] = useState();
  const [open, setOpen] = useState(false);
  const [dataFiles, setDataFiles] = useState();
  const [firmasList, setFirmasList] = useState([]);
  const [avalesList, setAvalesList] = useState([]);
  const [selectionList, setSelectionList] = useState();
  const validateForm = (e) => {
    e.preventDefault();
    if (excelFile && firmasList.length && avalesList.length) return true;
    else {
      setErrorMessage(
        "Debe ingresar el archivo Excel una Firma y un Aval como minimo."
      );
      setOpenDialog(true);
      return false;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm(e)) {
      const url = URL_BACKEND + "/api/importar_datos";
      if (excelFile) {
        const formData = new FormData();
        for (var [key, value] of Object.entries(excelFile)) {
          formData.append("file", value);
        }
        for (var [key, value] of Object.entries(firmasList)) {
          formData.append("firmas", value);
        }
        for (var [key, value] of Object.entries(avalesList)) {
          formData.append("avales", value);
        }

        const AuthStr = "Bearer ".concat(localStorage.getItem("access_token"));
        const config = {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: AuthStr,
          },
        };
        axios
          .post(url, formData, config)
          .then(function (response) {
            setOpenBar(true);
          })
          .catch(function (error) {
            setErrorMessage(error.response.data.detail);
            setOpenDialog(true);
          });
      }
    }
  };

  const openModal = (selection) => {
    setOpen(true);
    setSelectionList(selection);
  };

  return (
    <div style={useStyles.root}>
      <form noValidate onSubmit={(event) => handleSubmit(event)}>
        <Typography component="h1" variant="h5">
          Importar Datos
        </Typography>

        <input
          accept=".xls,.xlsx"
          style={{ display: "none" }}
          id="contained-button-file"
          type="file"
          name="templates"
          onChange={(e) => setExcelFile(e.target.files)}
        />
        <label htmlFor="contained-button-file">
          <Button
            variant="contained"
            color="primary"
            component="span"
            style={{ marginTop: 30 }}
            fullWidth
          >
            <CloudUploadIcon style={{ marginRight: 10 }} />
            Seleccionar Archivo Excel
          </Button>
        </label>
        <ul>{excelFile && excelFile.length && excelFile[0]?.name}</ul>

        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: 30 }}
          fullWidth
          onClick={() => openModal("firmas")}
        >
          <TableChart style={{ marginRight: 10 }} />
          Seleccionar Firmas
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: 30 }}
          fullWidth
          onClick={() => openModal("avales")}
        >
          <TableChart style={{ marginRight: 10 }} />
          Seleccionar Avales
        </Button>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          style={useStyles.submit}
        >
          Guardar
        </Button>
      </form>
      <div>
        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Error</DialogTitle>
          <DialogContent id="alert-dialog-description">
            <DialogContentText id="alert-dialog-description">
              {errorMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)} color="primary">
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <Modal
        open={open}
        handleClose={() => setOpen(false) && console.log(firmasList)}
      >
        <ModalContentFiles
          data={selectionList === "firmas" ? firmasList : avalesList}
          setData={selectionList === "firmas" ? setFirmasList : setAvalesList}
          title={"Imágenes " + selectionList}
        />
      </Modal>

      <div>
        <Snackbar
          open={openBar}
          autoHideDuration={6000}
          onClose={() => setOpenBar(false)}
        >
          <Alert
            onClose={() => setOpenBar(false)}
            severity="success"
            variant="filled"
          >
            Datos importados con exito!
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};

export default ImportData;
